import "./nav.scss";

import { useState } from "react";
import { IoIosArrowForward, IoIosSchool } from "react-icons/io";
import {
  IoChatbubbleEllipsesSharp,
  IoPersonAdd,
  IoGameController,
  IoGridSharp,
  IoBriefcase,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import { AuthContext } from "../../ContextApp/authContext";
const Nav = ({ loc }) => {
  const [width, setWidth] = useState(false); //Nav Width
  return (
    <AuthContext.Consumer>
      {({ adminrole }) => (
        <div
          className="navbar flex-center flex-dir-col flex-space-around"
          style={{
            width: width ? "24rem" : "8rem",
          }}
        >
          <h3
            style={{
              opacity: "0",
            }}
          >
            BEYLABEE
          </h3>
          <nav className="nav ">
            <ul className="nav__list flex-center flex-dir-col">
              <li className="nav__list__item flex-start flex-dir-row">
                <Link to="/">
                  <IoGridSharp
                    className={
                      loc === "home" ? "nav__icn nav__icn-active" : "nav__icn "
                    }
                  />
                </Link>
                {width ? (
                  <p
                    className={
                      loc === "home"
                        ? "nav__list__text-active nav__list__text"
                        : "nav__list__text "
                    }
                  >
                    HOME{adminrole}
                  </p>
                ) : null}
              </li>
              <li className="nav__list__item flex-start flex-dir-row">
                <Link to="/intern">
                  <IoIosSchool
                    className={
                      loc === "intern"
                        ? "nav__icn nav__icn-active"
                        : "nav__icn "
                    }
                  />
                </Link>
                {width ? (
                  <p
                    className={
                      loc === "intern"
                        ? "nav__list__text-active nav__list__text"
                        : "nav__list__text "
                    }
                  >
                    INTERN
                  </p>
                ) : null}
              </li>
              <li className="nav__list__item flex-start flex-dir-row">
                <Link to="/query">
                  <IoChatbubbleEllipsesSharp
                    className={
                      loc === "query" ? "nav__icn nav__icn-active" : "nav__icn "
                    }
                  />
                </Link>

                {width ? (
                  <p
                    className={
                      loc === "query"
                        ? "nav__list__text-active nav__list__text"
                        : "nav__list__text "
                    }
                  >
                    MESSAGES
                  </p>
                ) : null}
              </li>
              <li className="nav__list__item flex-start flex-dir-row">
                <Link to="/beequest">
                  <IoGameController
                    className={
                      loc === "competition"
                        ? "nav__icn nav__icn-active"
                        : "nav__icn "
                    }
                  />
                </Link>
                {width ? (
                  <p
                    className={
                      loc === "hSDome"
                        ? "nav__list__text-active nav__list__text"
                        : "nav__list__text "
                    }
                  >
                    COMPETITION
                  </p>
                ) : null}
              </li>{" "}
              <li className="nav__list__item flex-start flex-dir-row">
                <Link to="/job">
                  <IoBriefcase
                    className={
                      loc === "job" ? "nav__icn nav__icn-active" : "nav__icn "
                    }
                  />
                </Link>
                {width ? (
                  <p
                    className={
                      loc === "job"
                        ? "nav__list__text-active nav__list__text"
                        : "nav__list__text "
                    }
                  >
                    JOB
                  </p>
                ) : null}
              </li>
              {adminrole === "admin" ? (
                <li className="nav__list__item flex-start flex-dir-row">
                  <Link to="/admin">
                    <IoPersonAdd
                      className={
                        loc === "admin"
                          ? "nav__icn nav__icn-active"
                          : "nav__icn "
                      }
                    />
                  </Link>
                  {width ? (
                    <p
                      className={
                        loc === "admin"
                          ? "nav__list__text-active nav__list__text"
                          : "nav__list__text "
                      }
                    >
                      ADMINS
                    </p>
                  ) : null}
                </li>
              ) : null}
            </ul>
          </nav>
          <IoIosArrowForward
            className="nav__icn2"
            onClick={() => setWidth(!width)}
            style={{
              transform: width ? "rotate(180deg)" : "rotate(0)",
            }}
          />
        </div>
      )}
    </AuthContext.Consumer>
  );
};

export default Nav;
