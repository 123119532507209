import { AuthContext } from "../../ContextApp/authContext";

import "./login.scss";
const Login = () => {
  return (
    <AuthContext.Consumer>
      {({ user, handleAuth }) => (
        <>
          <div className="login background flex-center flex-space-between flex-dir-col">
            <p className="login__toptext color-light-gray">
              WELCOME TO BEYLABEE <span>DASHBOARD</span>
            </p>
            <div className="login__card card flex-center flex-dir-col">
              <img
                src="/images/logobeyla.png"
                alt="Logo Of Beylabee"
                className="logo"
              />
              <button
                className="login__btn flex-center flex-dir-row"
                onClick={handleAuth}
              >
                SIGN IN WITH GOOGLE
                <img
                  src="/images/google-logo.png"
                  alt="Google Logo"
                  className="login__btn__img"
                />
              </button>
              <p className="login__card__text color-light-gray">
                Only authorised accounts can access dashboard
              </p>
            </div>
            <div className="login__btm">
              <p className="login__btmtext color-light-gray">
                ORONIUM DASHBOARD SOLUTIONS
              </p>
              <p className="login__btmtext2 color-light-gray">
                Design and Developed by Oronium
              </p>
            </div>
          </div>
        </>
      )}
    </AuthContext.Consumer>
  );
};

export default Login;
