import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { MdViewDay, MdPerson, MdRecentActors } from "react-icons/md";
import { db } from "../../firebase/firebase";

import JobRequestsCard from "./jobRequestsCard";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleBackdrop from "../Login/backdrop";
////////////////////////Material UI Import Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
    secondary: {
      main: "#2979ff",
    },
  },
});
const JobRequests = () => {
  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  //////////////////////
  /////////////Get Job Requests
  //////////////////////
  const [jobrequestdata, setJobRequestdata] = useState(false);
  const ref = db.collection("jobrequest");
  function GetJobRequests(category) {
    console.log(category);
    const sortData =
      category === "All"
        ? ref.orderBy("createdAt", "desc")
        : ref
            .where("type", "==", category)
            .orderBy("createdAt", "desc")
            .limit(200);

    sortData.onSnapshot((querySnapshot) => {
      const jobdetails = [];
      querySnapshot.forEach((doc) => {
        jobdetails.push({ ...doc.data(), uniqid: doc.id });
        console.log(doc.data());
      });
      setJobRequestdata(jobdetails);
    });
  }

  useEffect(() => {
    GetJobRequests("All");
  }, []);

  //////////////////////
  /////////////Delete Confirmation Dialog
  //////////////////////
  const [openConfirm, setOpenConfirm] = React.useState(false); ////Usestate for Confirmation Dialog

  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setDeleteUserId(id);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  ///////////////////////////
  //////////////Delete Data
  //////////////////////////////
  const [deleteUserId, setDeleteUserId] = useState("");
  const [deleting, setDeletingstatus] = useState(false); //Status of Deleting

  const deleteQuery = () => {
    db.collection("jobrequest")
      .doc(deleteUserId)
      .delete()
      .then(() => {
        openSnackbar(true);
        setDeletingstatus(false);
        openSnackbarStatus({
          status: "success",
          text: "Document successfully deleted!",
        });
        HideAlert();
      })
      .catch((error) => {
        setDeletingstatus(false);
        openSnackbar(true);
        openSnackbarStatus({
          status: "success",
          text: "Error removing document",
        });
        HideAlert();
      });
  };
  return (
    <>
      <div className="card intern-card admin-card itemrow-jobreg-holder">
        <p className="subhead">Job Requests</p>
        <div className="flex-start flex-dir-row details-link">
          <div
            className="chips flex-start flex-dir-row"
            onClick={() => {
              GetJobRequests("All");
            }}
          >
            <MdViewDay size="1.5em" className="details-link__icn" />{" "}
            <p className="unread">All</p>
          </div>

          <div
            className="chips flex-start flex-dir-row"
            onClick={() => {
              GetJobRequests("Full Time");
            }}
          >
            <MdPerson size="1.5em" className="details-link__icn" />{" "}
            <p className="unread">FullTime</p>
          </div>

          <div
            className="chips flex-start flex-dir-row"
            onClick={() => {
              GetJobRequests("Intern");
            }}
          >
            <MdRecentActors size="1.5em" className="details-link__icn" />{" "}
            <p className="unread">Intern</p>
          </div>
        </div>
        <div className="list-holder list-holder-req ">
          {console.log(jobrequestdata)}
          {jobrequestdata ? (
            !(jobrequestdata.length === 0) ? (
              jobrequestdata.map((data) => {
                return (
                  <JobRequestsCard
                    key={data.uniqid}
                    data={data}
                    handleClickOpenConfirm={handleClickOpenConfirm}
                  />
                );
              })
            ) : (
              <div
                className="flex-center fullheight-2 flex-dir-row "
                style={{
                  flexDirection: "column",
                }}
              >
                <img
                  src="/images/Empty-bro.svg"
                  alt="Empty Space"
                  className="emptyimg"
                />
                <p className="inputholder__text flex-start job__itemrow__text2">
                  Nothing to Show
                </p>
              </div>
            )
          ) : (
            <div className="flex-center fullheight-2">
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span
              style={{
                fontFamily: "Jost",
                fontSize: "1.9rem",
                fontWeight: "500",
                color: "#2979ff",
              }}
            >
              {"Delete User Data"}
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span
                style={{
                  fontFamily: "inherit",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                }}
              >
                Do you really want to delete data from database.Once deleted
                data cannot be restored!
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                CANCEL
              </span>{" "}
            </Button>
            <Button
              onClick={() => {
                handleCloseConfirm();
                deleteQuery();
              }}
              autoFocus
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                DELETE
              </span>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
      {deleting ? <SimpleBackdrop /> : null}
    </>
  );
};

export default JobRequests;
