import Profile from "../Home/profile";
import Nav from "../Nav/nav";
import "./query.scss";

import React, { useState } from "react";

////////////////////////Material UI Import Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { db } from "../../firebase/firebase";
import { useEffect } from "react";
import QueryCard from "./queryCard";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
/////////////////////////Material UI Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleBackdrop from "../Login/backdrop";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
    secondary: {
      main: "#2979ff",
    },
  },
});

const QueryPanel = () => {
  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  //////////////////////
  /////////////Delete Confirmation Dialog
  //////////////////////
  const [openConfirm, setOpenConfirm] = React.useState(false); ////Usestate for Confirmation Dialog
  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  //////////////////////////////////
  ////////////Get Firebase data
  //////////////////////////////////
  const ref = db.collection("userreg");
  const [querydata, setQuerydata] = useState(false);

  function getFirebaseQueryData(category) {
    const sortData =
      category === "All"
        ? ref.orderBy("createdAt", "desc")
        : ref
            .where("category", "==", category)
            .orderBy("createdAt", "desc")
            .limit(200);

    sortData.onSnapshot((querysnapshot) => {
      let querydatalist = [];
      querysnapshot.forEach((doc) => {
        querydatalist.push({ ...doc.data(), uniqueid: doc.id });
      });
      setQuerydata(querydatalist);
      console.log(querydatalist);
      //
    });
  }
  useEffect(() => {
    getFirebaseQueryData("All");
  }, []); ////Get All Data

  ///////////////////////////
  //////////////Categories Slection
  //////////////////////////////
  const [categories, SetCategories] = useState(["All"]);
  useEffect(() => {
    //SetCategories([]);
    if (querydata) {
      querydata.forEach((data) => {
        if (
          !categories.includes(
            data.category === null ? "ContactForm" : data.category
          )
        ) {
          categories.push(
            data.category === null ? "ContactForm" : data.category
          );
        }
      });
    }
    console.log(categories);
  }, [querydata]);

  const [anchorEl, setAnchorEl] = React.useState(null); ///Categories List

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null); ///Categories List Close
  };

  ///////////////////////////
  //////////////Sort Data
  //////////////////////////////
  function SortData(category) {
    console.log(category);
    getFirebaseQueryData(category === "ContactForm" ? null : category);
  }
  ///////////////////////////
  //////////////Delete Data
  //////////////////////////////
  const [deleting, setDeletingstatus] = useState(false); //Status of Deleting
  const [deletedoc, setDeletedoc] = useState(""); //id of user to be deleted
  const getdeleteQueryDoc = (doc) => {
    setDeletedoc(doc);
  };
  const deleteQuery = (doc) => {
    db.collection("userreg")
      .doc(doc)
      .delete()
      .then(() => {
        openSnackbar(true);
        setDeletingstatus(false);
        openSnackbarStatus({
          status: "success",
          text: "Document successfully deleted!",
        });
        HideAlert();
      })
      .catch((error) => {
        setDeletingstatus(false);
        openSnackbar(true);
        openSnackbarStatus({
          status: "success",
          text: "Error removing document",
        });
        HideAlert();
      });
  };
  return (
    <>
      <div className="background base flex-center flex-dir-row">
        <Nav loc={"query"} />
        <Profile />
        <div className=" flex-start flex-dir-col  contentholder">
          <div className="contentholder__top">
            <h4>Student Leads</h4>
            <p className="con-text">Students data from Contact Forms </p>
          </div>
          <div className="grid adminpanel">
            <div className="card intern-card admin-card">
              <div className="flex-space-between  flex-dir-row details-link">
                <p className="subhead">Students Details</p>
                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <p
                      style={{
                        fontFamily: "Jost",
                        fontSize: "1.1rem",
                      }}
                    >
                      Sort Data By:
                    </p>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {categories
                      ? categories.map((item) => {
                          return (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                SortData(item);
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Jost",
                                  fontSize: "1.1rem",
                                }}
                              >
                                {item}
                              </p>
                            </MenuItem>
                          );
                        })
                      : null}
                  </Menu>
                </div>
              </div>
              <div className="list-holder list-holder2">
                {querydata ? (
                  querydata.length !== 0 ? (
                    querydata.map((data) => {
                      return (
                        <QueryCard
                          key={data.uniqueid}
                          data={data}
                          handleClickOpenConfirm={handleClickOpenConfirm}
                          getdeleteQueryDoc={getdeleteQueryDoc}
                        />
                      );
                    })
                  ) : (
                    <div
                      className="flex-center fullheight-2 flex-dir-row "
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src="/images/Empty-bro.svg"
                        alt="Empty Space"
                        className="emptyimg"
                      />
                      <p className="inputholder__text flex-start job__itemrow__text2">
                        Nothing to Show
                      </p>
                    </div>
                  )
                ) : (
                  <div className="flex-center fullheight-2">
                    <CircularProgress color="primary" />
                  </div>
                )}
              </div>
            </div>
            <div className="comp-col">&nbsp;</div>
          </div>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span
              style={{
                fontFamily: "Jost",
                fontSize: "1.9rem",
                fontWeight: "500",
                color: "#2979ff",
              }}
            >
              {"Delete User Data"}
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span
                style={{
                  fontFamily: "inherit",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                }}
              >
                Do you really want to delete data from database.Once deleted
                data cannot be restored!
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                CANCEL
              </span>{" "}
            </Button>
            <Button
              onClick={() => {
                handleCloseConfirm();
                deleteQuery(deletedoc);
              }}
              autoFocus
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                DELETE
              </span>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
      {deleting ? <SimpleBackdrop /> : null}
    </>
  );
};

export default QueryPanel;
