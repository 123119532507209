import React, { useState, useEffect } from "react";

////////////////////////Material UI Import Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

/////////////////////////Material UI Import Radio
import Radio from "@material-ui/core/Radio";
import {
  CircularProgress,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { db } from "../../firebase/firebase";
import JobOpportunityCard from "./jobOpportunity";
import { uuid } from "uuidv4";
/////////////////////////Material UI Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleBackdrop from "../Login/backdrop";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/////////////////
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
    secondary: {
      main: "#2979ff",
    },
  },
});
const JobDetails = () => {
  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  ////////////
  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  /////////////////////////////
  //////////////////// Get Data
  /////////////////////////////
  const [jobdata, setJobdata] = useState(false);
  const ref = db.collection("career");
  function GetJobDetails() {
    ref
      .get()
      .then((querySnapshot) => {
        const jobdetails = [];
        querySnapshot.forEach((doc) => {
          jobdetails.push({ ...doc.data(), uniqid: doc.id });
        });
        setJobdata(jobdetails);
      })
      .catch((error) => {
        openSnackbar(true);
        openSnackbarStatus({
          status: "error",
          text: "Something went wrong!",
        });
        HideAlert();
      });
  }
  useEffect(() => {
    GetJobDetails();
  }, []);
  /////////////////////////////
  //////////////////// Add Data
  /////////////////////////////
  const [jobTitle, SetJobtitle] = useState("");
  const [jobDescription, SetJobDescription] = useState("");
  const [jobposition, SetJobposition] = useState("");
  const [datachanges, SetDatchanges] = useState(false);
  function ResetInput() {
    SetJobtitle("");
    SetJobDescription("");
    SetJobposition("");
  }

  function AddJobPositionData() {
    if (
      jobDescription.length < 4 ||
      jobTitle.length < 4 ||
      jobposition.length === 0
    ) {
      openSnackbar(true);
      openSnackbarStatus({
        status: "error",
        text: "Please fill fields correctly!",
      });
      HideAlert();
    } else {
      SetDatchanges(true);
      handleCloseEdit();
      ref
        .add({
          description: jobDescription,
          field: (jobTitle + "-" + jobposition).replace(/\s+/g, ""),
          jobtitle: jobTitle,
          id: uuid(),
          type: jobposition,
        })
        .then(() => {
          SetDatchanges(false);

          openSnackbar(true);
          HideAlert();
          GetJobDetails();
          openSnackbarStatus({
            status: "success",
            text: "New Job position added!",
          });
        })
        .catch((error) => {
          SetDatchanges(false);
          openSnackbar(true);
          HideAlert();
          openSnackbarStatus({
            status: "error",
            text: "Something went wrong!",
          });
        });
    }
  }
  /////////////////////////////
  //////////////////// Delete Data
  /////////////////////////////
  const [openConfirm, setOpenConfirm] = React.useState(false); ////Usestate for Confirmation Dialog
  const [idDeleted, setDataidDeleted] = React.useState(""); ////Usestate for Confirmation Dialog
  const handleClickOpenConfirm = (data) => {
    setDataidDeleted(data);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  function Deletedata() {
    SetDatchanges(true);
    ref
      .doc(idDeleted)
      .delete()
      .then(() => {
        SetDatchanges(false);
        openSnackbar(true);
        ResetInput();
        HideAlert();
        openSnackbarStatus({
          status: "success",
          text: "Data successfully deleted!",
        });
        GetJobDetails();
      })
      .catch((err) => {
        SetDatchanges(false);
        openSnackbar(true);
        HideAlert();
        openSnackbarStatus({
          status: "error",
          text: "Something went wrong!",
        });
      });
  }
  /////////////////////////////
  //////////////////// Edit Data/updatedata
  /////////////////////////////
  const [actiontype, setActiontype] = useState("Add");
  const [idEdited, setidEdited] = useState("");
  const EditData = (data) => {
    setidEdited(data.uniqid);
    setActiontype("Edit");
    SetJobtitle(data.jobtitle);
    SetJobDescription(data.description);
    SetJobposition(data.field.includes("Intern") ? "Intern" : "Full Time");
  };
  function UpdateData() {
    if (
      jobDescription.length < 4 ||
      jobTitle.length < 4 ||
      jobposition.length === 0
    ) {
      openSnackbar(true);
      openSnackbarStatus({
        status: "error",
        text: "Please fill fields correctly!",
      });
      HideAlert();
    } else {
      SetDatchanges(true);
      handleCloseEdit();
      ref
        .doc(idEdited)
        .update({
          description: jobDescription,
          field: (jobTitle + "-" + jobposition).replace(/\s+/g, ""),
          jobtitle: jobTitle,
          type: jobposition,
        })
        .then(() => {
          SetDatchanges(false);
          openSnackbar(true);
          ResetInput();
          HideAlert();
          openSnackbarStatus({
            status: "success",
            text: "Data successfully Updated!",
          });
          GetJobDetails();
        })
        .catch((error) => {
          SetDatchanges(false);
          openSnackbar(true);
          HideAlert();
          openSnackbarStatus({
            status: "error",
            text: "Something went wrong!",
          });
        });
    }
  }
  return (
    <>
      <div className="card intern-card admin-card">
        <p className="subhead">Job Options</p>
        <div className="list-holder">
          {jobdata ? (
            jobdata.map((data) => {
              return (
                <JobOpportunityCard
                  data={data}
                  key={data.uniqid}
                  handleClickOpenConfirm={handleClickOpenConfirm}
                  EditData={EditData}
                  handleClickOpenEdit={handleClickOpenEdit}
                />
              );
            })
          ) : (
            <div className="flex-center fullheight-2">
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
        <div className="adminpanel__btm flex-end flex-dir-row">
          <button
            className="adminpanel__btm__add"
            onClick={() => {
              setActiontype("Add");
              handleClickOpenEdit();
              ResetInput();
            }}
          >
            ADD JOB POSITION
          </button>
        </div>
      </div>

      <ThemeProvider theme={theme}>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span
              style={{
                fontFamily: "Jost",
                fontSize: "1.9rem",
                fontWeight: "500",
                color: "#2979ff",
              }}
            >
              {"Delete Job Position"}
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span
                style={{
                  fontFamily: "inherit",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                }}
              >
                Do you really want to delete job position.Once deleted data
                cannot be restored
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                CANCEL
              </span>{" "}
            </Button>
            <Button
              onClick={() => {
                handleCloseConfirm();
                Deletedata();
              }}
              autoFocus
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                DELETE
              </span>
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>
            <span
              style={{
                fontFamily: "Jost",
                fontSize: "1.9rem",
                fontWeight: "500",
                color: "#2979ff",
              }}
            >
              {actiontype} Job Position
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                }}
              >
                Add new Full Time/Intern Job positions to Beylabee Careers.
              </span>
            </DialogContentText>
            <div className="inputholder">
              <p className="inputholder__text">Job Title</p>
              <input
                onChange={(e) => {
                  SetJobtitle(e.target.value);
                }}
                type="text"
                defaultValue={jobTitle}
                className="inputholder__box"
                placeholder="Job title eg: Content Writer"
              />
            </div>
            <div className="inputholder">
              <p className="inputholder__text">Job Description</p>
              <input
                defaultValue={jobDescription}
                onChange={(e) => {
                  SetJobDescription(e.target.value);
                }}
                type="text"
                className="inputholder__box"
                placeholder="Short Job Description"
              />
            </div>
            <div className="inputholder">
              <p className="inputholder__text">Role</p>
              <RadioGroup
                defaultValue={jobposition}
                row
                aria-label="jobtype"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  SetJobposition(e.target.value);
                }}
              >
                <FormControlLabel
                  value="Intern"
                  control={<Radio />}
                  label="Intern"
                />
                <FormControlLabel
                  value="Full Time"
                  control={<Radio size="medium" />}
                  label="Full Time"
                />
              </RadioGroup>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                Cancel
              </span>
            </Button>
            <Button
              onClick={() => {
                if (actiontype === "Add") {
                  AddJobPositionData();
                } else {
                  UpdateData();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                {actiontype === "Edit" ? "SAVE CHANGES" : "ADD POSITION"}
              </span>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
      {datachanges ? <SimpleBackdrop /> : null}
    </>
  );
};
export default JobDetails;
