import { Link } from "react-router-dom";
import { AuthContext } from "../../ContextApp/authContext";
import "./home.scss";

const Profile = () => {
  return (
    <AuthContext.Consumer>
      {({ user, handleSignOut }) => (
        <div className="profile">
          <img
            src={user ? user.photoURL : ""}
            alt="Profile"
            className="profile__img"
          />
          <div className="profile__dropdown card">
            <ul className="profile__dropdown__list">
              <li>
                <Link className="profile__dropdown__list__item">
                  {user ? user.displayName : ""}
                </Link>
              </li>
              <li onClick={handleSignOut}>
                <Link className="profile__dropdown__list__item">LOGOUT</Link>
              </li>
              <li>
                <Link
                  className="profile__dropdown__list__item"
                  onClick={() => {
                    window.open("https://beylabee.com/", "_blank");
                  }}
                >
                  WEBSITE
                </Link>
              </li>
              <li>
                <Link className="profile__dropdown__list__item">HELP</Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  );
};

export default Profile;
