import React, { useState } from "react";
import {
  MdDelete,
  MdMailOutline,
  MdCall,
  MdAssignmentInd,
  MdContentCopy,
} from "react-icons/md";
import copy from "copy-to-clipboard";
/////////////////////////Material UI Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const copyCodeToClipboard = (data) => {
  copy(data);
};

const JobRequestsCard = ({ data, handleClickOpenConfirm }) => {
  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  return (
    <>
      <div className="adminpanel__itemrow grid job__itemrow itemrow-jobreg">
        <p className="inputholder__text flex-start ">Name:{data.name}</p>
        <p className="inputholder__text flex-start job__itemrow__text2">
          {data.job}
        </p>
        <div className="flex-start flex-dir-row details-link details-link2">
          <MdMailOutline size="1.8em" className="details-link__icn" />
          <a href="mailto:">
            <p className="job__itemrow__text3"> {data.mail}</p>
          </a>
        </div>
        <div className="flex-start flex-dir-row details-link details-link2">
          <MdAssignmentInd size="1.8em" className="details-link__icn" />
          <div
            href="#"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              copy(data.resume);
              openSnackbar(true);
              openSnackbarStatus({
                status: "success",
                text: `${data.name}'s Resume Link Copied! `,
              });
              HideAlert();
            }}
          >
            <p className="job__itemrow__text3">
              {data.resume.length > 30
                ? data.resume.slice(0, 30) + "......."
                : data.resume}
            </p>
          </div>
        </div>
        <div className="flex-start flex-dir-row details-link details-link2">
          <MdCall size="1.8em" className="details-link__icn" />
          <a href={`tel:${data.phone}`}>
            <p className="job__itemrow__text3">{data.phone}</p>
          </a>
        </div>
        <p className="inputholder__text flex-start adminpanel__itemrow__text2 query-date">
          {data.createdAt.toDate().toString()}
        </p>
        <div className="adminpanel__itemrow__more flex-dir-row flex-end">
          <div
            className="circle-admin circle-admin-absolute flex-center circle-admin-delete "
            onClick={() => {
              handleClickOpenConfirm(data.uniqid);
            }}
          >
            <MdDelete size="1.7em" color="#ff1744" />
          </div>

          <div
            className="circle-admin circle-admin-absolute circle-admin-absolute-2 flex-center circle-admin-copy "
            onClick={() => {
              openSnackbar(true);
              openSnackbarStatus({
                status: "success",
                text: `${data.name}'s details Copied! `,
              });
              HideAlert();
              copyCodeToClipboard(
                `👨‍🎓Name:${data.name} \n 🕴Job:${data.job} \n 📧Mail:${data.mail} \n 📞PhNo:${data.phone} \n Resume:${data.resume}`
              );
            }}
          >
            <MdContentCopy size="1.7em" color="#ffc400" />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};
export default JobRequestsCard;
