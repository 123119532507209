import Profile from "../Home/profile";
import Nav from "../Nav/nav";
import "./intern.scss";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdCreate, MdClose, MdDone } from "react-icons/md";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase/firebase";
import SimpleBackdrop from "../Login/backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
//////////////////Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
  },
});
/////////////////
/////////////////////////////////////////

const Intern = () => {
  const [fab, setFAB] = useState(false); ///fab Button
  /////////////////////InputFields //////////////
  ///////////////////////////////////////

  const [intername, setInternname] = useState("");
  const [institution, setInstitution] = useState("");
  const [linkdein, setLinkdin] = useState("");
  const [image, setImage] = useState("");
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState(false); ////Response type
  const [uploadedit, setUploadEdit] = useState(false); ////Response type
  const [imageAsFile, setImageAsFile] = useState(""); //Image As File Upload

  /////////////////////Get Data //////////////
  ///////////////////////////////////////

  const [intern, setIntern] = useState(false); ////Holds Intern Data From Firebase
  const ref = db.collection("intern");

  function getData() {
    ref
      .doc("auvkKBulr5R3oqTdkYD7")
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIntern(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        openSnackbarStatus("error");
        openSnackbar(true);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setData();
  }, [intern]);
  //////////////////////////////////////////
  /////////////Handle Edit///////////////
  //////////////////////////////////////////

  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  function setData() {
    //Data from Server Asigned to UseState Variables
    setInternname(intern.internname);
    setInstitution(intern.college);
    setImage(intern.img);
    setLinkdin(intern.linkdin);
  }
  function handleInternEdit() {
    ///Submit Edit to Firebase

    if (
      intername !== intern.internname ||
      institution !== intern.college ||
      linkdein !== intern.linkdin ||
      image !== intern.img
    ) {
      setUploadEdit(true);
      ref
        .doc("auvkKBulr5R3oqTdkYD7")
        .set({
          college: institution,
          img: image,
          internname: intername,
          linkdin: linkdein,
        })
        .then(() => {
          setUploadEdit(false);
          openSnackbarStatus("success");
          openSnackbar(true);
          getData();
          HideAlert();
          setFAB(false);
        })
        .catch((err) => {
          setUploadEdit(false);
          openSnackbarStatus("error");
          openSnackbar(true);
          HideAlert();
        });
    }
  }
  useEffect(() => {
    getData();
  }, []);
  //////////////////////////////////////////
  /////////////image Upload///////////////
  //////////////////////////////////////////

  const handleFireBaseUpload = () => {
    ///Upload Image To Firebase
    if (imageAsFile === "") {
      console.log("unsupported format");
    }
    setUploadEdit(true);
    // async magic goes here...
    const ref = storage.ref(`/images/${imageAsFile.name}`);
    const uploadTask = ref.put(imageAsFile);
    uploadTask.on(
      "state_changed",
      () => {},
      (err) => {
        openSnackbarStatus("error");
        openSnackbar(true);
        setFAB(false);
        setUploadEdit(false);
        HideAlert();
      },
      () => {
        ref.getDownloadURL().then((url) => {
          setImage(url);
          setUploadEdit(false);
        });
      }
    );
  };
  function handleImageAsFile(e) {
    ///Change From Input ASSIGN tO VAR
    setImageAsFile(e.target.files[0]);
  }
  useEffect(() => {
    console.log(imageAsFile);
    if (imageAsFile) {
      handleFireBaseUpload();
    }
  }, [imageAsFile]);
  //////////////////////////////////////////
  //////////////////////////////////////////
  return (
    <>
      {uploadedit ? <SimpleBackdrop /> : null}
      <ThemeProvider theme={theme}>
        <div className="background base flex-center flex-dir-row">
          <Nav loc={"intern"} />
          <Profile />
          <div className=" flex-start flex-dir-col  contentholder">
            <div className="contentholder__top">
              <h4>Intern</h4>
              <p className="con-text">Edit Intern of the month Details </p>
            </div>
            <div className="grid intern">
              <div
                className="card intern-card"
                style={{
                  minHeight: "30rem",
                }}
              >
                <p className="subhead">Intern of the Month</p>
                {intern ? (
                  <>
                    <div className="inputholder">
                      <p className="inputholder__text">
                        Intern Name<span>*</span>
                      </p>
                      <input
                        type="text"
                        className="inputholder__box"
                        placeholder="Name of Intern"
                        value={
                          !fab ? (intern ? intern.internname : null) : null
                        }
                        disabled={!fab}
                        onChange={(e) => {
                          setInternname(e.target.value);
                        }}
                      />
                    </div>
                    <div className="inputholder">
                      <p className="inputholder__text">
                        Institution<span>*</span>
                      </p>
                      <input
                        disabled={!fab}
                        type="text"
                        className="inputholder__box"
                        placeholder="Name of Institution"
                        value={!fab ? (intern ? intern.college : null) : null}
                        onChange={(e) => {
                          setInstitution(e.target.value);
                        }}
                      />
                    </div>
                    <div className="inputholder">
                      <p className="inputholder__text">Linkedin Profile URL</p>
                      <input
                        disabled={!fab}
                        type="text"
                        className="inputholder__box"
                        placeholder="Linkedin Profile URL"
                        value={!fab ? (intern ? intern.linkdin : null) : null}
                        onChange={(e) => {
                          setLinkdin(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex-center full-height">
                    <CircularProgress color="primary" />
                  </div>
                )}
              </div>
              <div
                className="card intern-card"
                style={{
                  minHeight: "30rem",
                }}
              >
                <p className="subhead">Upload Image</p>

                {intern ? (
                  <>
                    {intern ? (
                      <img
                        src={image}
                        alt="Intern of the month"
                        className="intern__img"
                      />
                    ) : null}

                    <p className="inputholder__text">Choose an Image</p>
                    <input
                      className="upload"
                      type="file"
                      id="file"
                      accept="image/png, image/jpeg"
                      onChange={handleImageAsFile}
                      disabled={!fab}
                    />
                    <div className="flex-center">
                      <label for="file" className="flex-center">
                        Choose a file
                        <AiOutlineCloudUpload
                          style={{
                            fontSize: "2em",
                            marginLeft: "1rem",
                            color: "var(--color-primary)",
                          }}
                        />
                      </label>
                    </div>

                    <p className="inputholder__text flex-center">OR</p>
                    <div
                      className="inputholder"
                      style={{
                        marginTop: "0",
                      }}
                    >
                      <p className="inputholder__text">Image URL</p>
                      <input
                        disabled={!fab}
                        type="text"
                        className="inputholder__box"
                        placeholder="URL of Image"
                        value={true ? (intern ? image : null) : null}
                        onChange={(e) => {
                          setImage(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex-center full-height">
                    <CircularProgress color="primary" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      {fab ? (
        <>
          <div
            className="fab fab__fab1 fab__bg1 flex-center"
            onClick={() => {
              setFAB(false);
              setImage(intern.img);
            }}
          >
            <MdClose className="fab__icon" />
          </div>
          <div
            className="fab fab__fab2 fab__bg2 flex-center"
            onClick={() => {
              handleInternEdit();
            }}
          >
            <MdDone className="fab__icon" />
          </div>
        </>
      ) : (
        <div
          className="fab fab__fab1 fab__bg flex-center"
          onClick={() => {
            setFAB(true);
          }}
        >
          <MdCreate className="fab__icon" />
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">Something went wrong</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Intern;
