import React, { useState } from "react";
import { MdDelete, MdCall, MdContentCopy } from "react-icons/md";
import copy from "copy-to-clipboard";

/////////////////////CopyText
/////////////////////////Material UI Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import SimpleBackdrop from "../Login/backdrop";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const copyCodeToClipboard = (data) => {
  copy(data);
};
const QueryCard = ({ data, handleClickOpenConfirm, getdeleteQueryDoc }) => {
  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 3000);
  }
  return (
    <>
      <div className="adminpanel__itemrow grid category-card query-item">
        <p className="inputholder__text flex-start ">{data.name}</p>
        <p className="inputholder__text flex-start adminpanel__itemrow__text2 categorytext">
          {data.category === null ? "ContactForm" : data.category}
        </p>
        <div className="adminpanel__itemrow__more flex-dir-row query-icn">
          <div
            className="circle-admin  circle-admin-absolute-2 flex-center circle-admin-copy "
            onClick={() => {
              openSnackbar(true);
              openSnackbarStatus({
                status: "success",
                text: `${data.name}'s Details Copied! `,
              });
              HideAlert();
              copyCodeToClipboard(
                `👨‍🎓Name:${data.name} \n 🎪Category:${
                  data.category === null ? "ContactForm" : data.category
                } \n ☎PhNo:${data.num} \n Class:${
                  data.userclass
                } \n SubCategory:${data.subcategory}`
              );
            }}
          >
            <MdContentCopy size="1.7em" color="#ffc400" />
          </div>
          <div
            className="circle-admin flex-center circle-admin-delete "
            onClick={() => {
              handleClickOpenConfirm();
              getdeleteQueryDoc(data.uniqueid);
              // handleClickOpenConfirm();
            }}
          >
            <MdDelete size="1.7em" color="#ff1744" />
          </div>
        </div>
        <div className="flex-start flex-dir-row">
          <MdCall size="1.8em" className="details-link__icn" />{" "}
          <p className="inputholder__text flex-start ">{data.num}</p>
        </div>
        <div className="">
          <p className="inputholder__text flex-start adminpanel__itemrow__text2">
            Class: {data.userclass}
          </p>
          {data.subcategory !== "nodata" ? (
            <p className="inputholder__text flex-start adminpanel__itemrow__text2">
              SubCategory: {data.subcategory}
            </p>
          ) : null}
        </div>
        &nbsp;
        <p className="inputholder__text flex-start adminpanel__itemrow__text2 query-date">
          {data.createdAt.toDate().toString()}
        </p>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default QueryCard;
