import Profile from "../Home/profile";
import Nav from "../Nav/nav";
import "./admin.scss";
import SimpleBackdrop from "../Login/backdrop";
import React from "react";
import { useEffect, useState } from "react";
////////////////////////Material UI Import Dialog

import CircularProgress from "@material-ui/core/CircularProgress";
import { db } from "../../firebase/firebase";

import AdminRoleCard from "./adminRoleCard";
import NewAdminDialogue from "./addAdminDialogue";
import ConfirmDeleteDialogue from "./confirmDelete";
//////////////////Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import EditAdminDialogue from "./EditAdminDialogue copy";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

///////////
/////////////////
const Adminpanel = ({ usermail }) => {
  ///////////////////////////////
  /////////////////Snackbar
  ///////////////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  ///////////////////////////////
  /////////////////Add New Admin
  ///////////////////////////////
  const [admin, setAdmin] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleClickOpenEdit = (e) => {
    console.log(e);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  ///////////////////////////////
  /////////////////Edit Admin
  ///////////////////////////////

  const [editAdminData, setEditAdminData] = useState(""); ////Holds Intern Data From Firebase
  const [editAdmin, setEditAdmin] = useState(false);
  const handleClickOpenAdminEdit = (e) => {
    setEditAdminData(e);

    setEditAdmin(true);
  };
  const handleCloseAdminEdit = () => {
    setEditAdmin(false);
  };
  ///////////////////////////////
  /////////////////Delete Admin
  ///////////////////////////////
  const [deletingdata, setDeletingdata] = useState(false); ////Holds Intern Data From Firebase
  const [deleteadmin, setDeleteAdmin] = useState(""); ////Holds Intern Data From Firebase

  ///////////////////////////////
  ///////////////// Confirm Dialogue
  ///////////////////////////////

  const [openConfirm, setOpenConfirm] = React.useState(false); ////Usestate for Confirmation Dialog
  const handleClickOpenConfirm = (uniqueid) => {
    setDeleteAdmin(uniqueid);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleCloseConfirmDelete = () => {
    setOpenConfirm(false);
    DeleteAdmin();
  };

  /////////////////////Get Data //////////////
  ///////////////////////////////////////

  const ref = db.collection("adminroles");
  const handleGetData = () => {
    getData(); ///For Transferring Function To component
  };
  function getData() {
    let admins = [];
    ref
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          admins.push({ ...doc.data(), uniqueid: doc.id });
        });
        setAdmin(admins);
      })
      .catch((error) => {
        openSnackbar(true);
        openSnackbarStatus({
          status: "error",
          text: "Something Went Wrong",
        });
        HideAlert();
      });
  }

  /////////////////////Delete Admin Data //////////////
  ///////////////////////////////////////
  function DeleteAdmin() {
    setDeletingdata(true);
    ref
      .doc(deleteadmin)
      .delete()
      .then(() => {
        getData();
        setDeletingdata(false);
        openSnackbar(true);
        openSnackbarStatus({
          status: "Success",
          text: "User Removed",
        });
        HideAlert();
      })
      .catch((error) => {
        openSnackbar(true);
        openSnackbarStatus({
          status: "error",
          text: "Something Went Wrong",
        });
        HideAlert();
      });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {deletingdata ? <SimpleBackdrop /> : null}
      <div className="background base flex-center flex-dir-row">
        <Nav loc={"admin"} />
        <Profile />
        <div className=" flex-start flex-dir-col  contentholder">
          <div className="contentholder__top">
            <h4>Authorised Admins</h4>
            <p className="con-text">Add and Edit Admins </p>
          </div>
          <div className="grid adminpanel">
            <div className="card intern-card admin-card">
              <p className="subhead">Admin Panel</p>
              <div className="list-holder">
                <div className="adminpanel__titlerow grid">
                  <p className="inputholder__text">GMail</p>
                  <p className="inputholder__text">Role</p>
                  &nbsp;
                </div>

                {!(admin.length === 0) ? (
                  admin.map((data) => (
                    <AdminRoleCard
                      usermail={usermail}
                      data={data}
                      key={data.id}
                      handleClickOpenEdit={handleClickOpenAdminEdit}
                      handleClickOpenConfirm={handleClickOpenConfirm}
                    />
                  ))
                ) : (
                  <div className="flex-center full-height">
                    <CircularProgress color="primary" />
                  </div>
                )}
              </div>
              <div className="adminpanel__btm flex-end flex-dir-row">
                <button
                  className="adminpanel__btm__add"
                  onClick={handleClickOpenEdit}
                >
                  ADD ADMIN
                </button>
              </div>
            </div>
            <div className="comp-col">&nbsp;</div>
          </div>
        </div>
      </div>
      {
        ////////////////
        ///Dialogue for Add New Admin
        //////
      }
      <NewAdminDialogue
        handleCloseEdit={handleCloseEdit}
        openEdit={openEdit}
        admin={admin}
        handleGetData={handleGetData}
      />
      {
        ////////////////
        ///Dialogue for Confirm Delete of New Admin
        //////
      }
      <ConfirmDeleteDialogue
        openConfirm={openConfirm}
        handleCloseConfirm={handleCloseConfirm}
        handleCloseConfirmDelete={handleCloseConfirmDelete}
      />
      {
        ////////////////
        ///Dialogue for Edit Admin
        //////
      }
      <EditAdminDialogue
        handleCloseEdit={handleCloseAdminEdit}
        editAdmin={editAdmin}
        admin={admin}
        handleGetData={handleGetData}
        editAdminData={editAdminData}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Adminpanel;
/*

*/
