import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login/login";
import Home from "./pages/Home/home";
import Intern from "./pages/Intern/intern";
import Competition from "./pages/Competition/competition";
import Adminpanel from "./pages/admin/admin";
import Job from "./pages/job/job";
import QueryPanel from "./pages/query/query";
import AuthContextProvider, { AuthContext } from "./ContextApp/authContext";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContextProvider>
          <AuthContext.Consumer>
            {({ user }) => (
              <Switch>
                <Route exact path="/login">
                  {user ? <Redirect to="/login" /> : <Login />}
                </Route>
                <Route exact path="/">
                  {!user ? <Redirect to="/login" /> : <Home />}
                </Route>

                <Route exact path="/intern">
                  {!user ? <Redirect to="/login" /> : <Intern />}
                </Route>

                <Route exact path="/beequest">
                  {!user ? <Redirect to="/login" /> : <Competition />}
                </Route>
                <Route exact path="/admin">
                  {!user ? (
                    <Redirect to="/login" />
                  ) : (
                    <Adminpanel usermail={user.email} />
                  )}
                </Route>
                <Route exact path="/job">
                  {!user ? <Redirect to="/login" /> : <Job />}
                </Route>
                <Route exact path="/query">
                  {!user ? <Redirect to="/login" /> : <QueryPanel />}
                </Route>
                <Route path="/">
                  {user ? <Redirect to="/login" /> : <Login />}
                </Route>
              </Switch>
            )}
          </AuthContext.Consumer>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
