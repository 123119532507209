import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, db, provider } from "../firebase/firebase";
import SimpleBackdrop from "../pages/Login/backdrop";
import MuiAlert from "@material-ui/lab/Alert";

//////////////////Alert
import Snackbar from "@material-ui/core/Snackbar";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
/////////////////

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  /////////////////////////////////////////
  /////////////////////Auth //////////////
  ///////////////////////////////////////
  let history = useHistory();
  const [username, setUser] = useState(false);
  ////AuthChange
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        history.push("/");
      }
    });
  });
  ////Login
  const handleAuth = () => {
    openSnackbar(false);
    if (!username) {
      auth.signInWithPopup(provider).then((result) => {
        if (adminroles.includes(result.user.email)) {
          setUser(result.user);
          history.push("/");
        } else {
          auth.signOut().then(() => {
            history.push("/login");
            openSnackbar(true);
            setUser(false);
            setAdmin(false);
            getData();
          });
        }
      });
    }
  };
  ////SignOut
  const handleSignOut = () => {
    if (username) {
      auth.signOut().then(() => {
        setUser(false);
        setAdmin(false);
        getData();
        history.push("/login");
      });
    }
  };

  /////////////////////////////////////////
  /////////////////////Admin Roles //////////////
  ///////////////////////////////////////

  const [admin, setAdmin] = useState(false);
  const ref = db.collection("adminroles");
  let adminroles = [];
  function getData() {
    ref.get().then((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
        // setcareerdataLoad(false);
      });
      setAdmin(items);
    });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (admin) {
      admin.forEach((item) => {
        adminroles.push(item.gmail);
      });
    }
  }, [admin]);
  const [adminrole, SetAdminRole] = useState("");
  useEffect(() => {
    console.log("item.role");
    if (admin) {
      admin.forEach((item) => {
        if (item.gmail === username.email) {
          console.log(item.gmail, username.email);
          SetAdminRole(item.role);
        }
      });
    }
  }, [admin]);
  //////////////////////////////////////////
  //////////////////////////////////////////

  const [snakbar, openSnackbar] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        user: username,
        handleAuth,
        handleSignOut,
        adminrole: adminrole,
      }}
    >
      {props.children}
      {!admin ? <SimpleBackdrop /> : null}
      <Snackbar
        Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
        autoHideDuration={6000}
      >
        <Alert
          autoHideDuration={6000}
          onClose={() => {
            openSnackbar(false);
          }}
          severity="error"
        >
          <p className="alerttext">Not an authorised account</p>
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
