import React from "react";
import { MdCreate, MdDelete } from "react-icons/md";
const AdminRoleCard = ({
  usermail,
  data,
  handleClickOpenConfirm,
  handleClickOpenEdit,
}) => {
  return (
    <div key={data.id} className="adminpanel__itemrow grid">
      <p className="inputholder__text flex-start ">{data.gmail}</p>
      <p className="inputholder__text flex-start adminpanel__itemrow__text2">
        {data.role.toUpperCase()}
      </p>
      <div className="adminpanel__itemrow__more flex-dir-row ">
        <div
          className="circle-admin flex-center circle-admin-edit"
          onClick={() => {
            if (usermail !== data.gmail) {
              handleClickOpenEdit(data);
            }
          }}
        >
          <MdCreate size="1.7em" color="#2979FF" />
        </div>
        <div
          className="circle-admin flex-center circle-admin-delete "
          onClick={() => {
            if (usermail !== data.gmail) {
              handleClickOpenConfirm(data.uniqueid);
            }
          }}
        >
          <MdDelete size="1.7em" color="#ff1744" />
        </div>
      </div>
    </div>
  );
};
export default AdminRoleCard;
