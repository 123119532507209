import React from "react";
import { MdCreate, MdDelete } from "react-icons/md";
const JobOpportunityCard = ({
  data,
  handleClickOpenConfirm,
  EditData,
  handleClickOpenEdit,
}) => {
  return (
    <div className="adminpanel__itemrow grid job__itemrow ">
      <p className="inputholder__text flex-start ">{data.jobtitle}</p>
      <p className="inputholder__text flex-start job__itemrow__text2">
        {data.field}
      </p>
      <p className="job__itemrow__text3">
        {data.description.length > 200
          ? data.description.slice(0, 200) + "....."
          : data.description}
      </p>
      <div className="adminpanel__itemrow__more flex-dir-row flex-end">
        <div
          className="circle-admin flex-center circle-admin-edit"
          onClick={() => {
            EditData(data);
            handleClickOpenEdit();
          }}
        >
          <MdCreate size="1.7em" color="#2979FF" />
        </div>
        <div
          className="circle-admin flex-center circle-admin-delete "
          onClick={() => {
            handleClickOpenConfirm(data.uniqid);
          }}
        >
          <MdDelete size="1.7em" color="#ff1744" />
        </div>
      </div>
    </div>
  );
};
export default JobOpportunityCard;
