import "./home.scss";

const Barchart = ({ data }) => {
  return (
    <div className="barchart">
      <div
        className="barchart__total"
        style={{
          height: data[0] + "%",
        }}
      ></div>
      <div
        className="barchart__unique"
        style={{
          height: data[1] + "%",
        }}
      ></div>
    </div>
  );
};

export default Barchart;
