import React from "react";
////////////////////////Material UI Import Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

/////////////////////////Material UI Import Radio

//////////////////Alert

const ConfirmDeleteDialogue = ({
  handleCloseConfirm,
  openConfirm,
  handleCloseConfirmDelete,
}) => {
  return (
    <Dialog
      open={openConfirm}
      onClose={handleCloseConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span
          style={{
            fontFamily: "Jost",
            fontSize: "1.9rem",
            fontWeight: "500",
            color: "#2979ff",
          }}
        >
          {"Do you want to remove a user?"}
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span
            style={{
              fontFamily: "inherit",
              fontSize: "1.6rem",
              fontWeight: "400",
            }}
          >
            Do you really want remove.Once removed user won't be able access
            Dashboard
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirm}>
          <span
            style={{
              fontFamily: "Jost",
              fontSize: "1.4rem",
              fontWeight: "500",
            }}
          >
            CANCEL
          </span>{" "}
        </Button>
        <Button onClick={handleCloseConfirmDelete} autoFocus>
          <span
            style={{
              fontFamily: "Jost",
              fontSize: "1.4rem",
              fontWeight: "500",
            }}
          >
            DELETE
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialogue;
