import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDRVS_e-Jt8FjPmiH48rKyyyynbSchHsis",
  authDomain: "beyla-backend.firebaseapp.com",
  projectId: "beyla-backend",
  storageBucket: "beyla-backend.appspot.com",
  messagingSenderId: "964955453891",
  appId: "1:964955453891:web:56ecaf341370db7dba2576",
  measurementId: "G-TXZVZZRNN2",
};
const firebaseapp = firebase.initializeApp(firebaseConfig);
const db = firebaseapp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const provider = new firebase.auth.GoogleAuthProvider();
//const storage = firebase.storage();

export { auth, provider, db, storage };
