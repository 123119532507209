import Profile from "../Home/profile";
import Nav from "../Nav/nav";
import "./competition.scss";
import { db } from "../../firebase/firebase";
import { MdCreate, MdClose, MdDone } from "react-icons/md";
import { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleBackdrop from "../Login/backdrop";
//////////////////Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Competition = () => {
  const [fab, setFAB] = useState(false); ////FAB actions

  //////////////////////
  /////////////Snackbar
  //////////////////////
  const [snakbar, openSnackbar] = useState(false); ////Sankbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }
  ///////////////////////////////
  ///////Get Data//////////
  ///////////////////////////////
  const [compactive, setCompactive] = useState(false); ////Competition Active
  const [competitiondata, setCompetitiondata] = useState(""); ////Competition Active
  const [competitionLinks, setCompetitionLinks] = useState({}); ////competition links /change value from inputs
  const ref = db.collection("competition").doc("JR2NzgZSkyZlWoLfm7Tc");
  function getCompetitionData() {
    ref
      .get()
      .then((doc) => {
        setCompetitiondata(doc.data());
      })
      .catch((err) => {
        openSnackbar(true);
        openSnackbarStatus({
          status: "error",
          text: "Something Went Wrong",
        });
        HideAlert();
      });
  }
  useEffect(() => {
    getCompetitionData();
  }, []);

  function SetInitialData() {
    setCompetitionLinks({
      link1: competitiondata.link1,
      link2: competitiondata.link2,
      link3: competitiondata.link3,
    });

    setCompactive(competitiondata.status);
  }
  useEffect(() => {
    if (competitiondata) {
      SetInitialData();
    }
  }, [competitiondata]);

  //////////////////////////////////////
  //////////////////Update Data
  //////////////////////
  const [uploading, SetUploadingStataus] = useState(false);
  function UploadCompetitionData() {
    if (
      !(
        competitionLinks.link1.length > 5 &&
        competitionLinks.link2.length > 5 &&
        competitionLinks.link3.length > 5
      )
    ) {
      SetUploadingStataus(false);
      openSnackbar(true);
      HideAlert();
      openSnackbarStatus({
        status: "error",
        text: "Please add valid URLS",
      });
    } else if (
      competitionLinks.link1 !== competitiondata.link1 ||
      competitionLinks.link2 !== competitiondata.link2 ||
      competitionLinks.link3 !== competitiondata.link3 ||
      compactive !== competitiondata.status
    ) {
      SetUploadingStataus(true);
      ref
        .set({
          ...competitionLinks,
          status: compactive,
        })
        .then(() => {
          SetUploadingStataus(false);
          openSnackbar(true);
          HideAlert();
          openSnackbarStatus({
            status: "success",
            text: "Changes Updated",
          });
          setCompetitiondata("");
          getCompetitionData();
        })
        .catch((error) => {
          SetUploadingStataus(false);
          openSnackbar(true);
          HideAlert();
          SetInitialData();
          openSnackbarStatus({
            status: "error",
            text: "Something Went Wrong",
          });
        });
    }
  }
  return (
    <>
      {uploading ? <SimpleBackdrop /> : null}
      <ThemeProvider theme={theme}>
        <div className="background base flex-center flex-dir-row">
          <Nav loc={"competition"} />
          <Profile />
          <div className=" flex-start flex-dir-col  contentholder">
            <div className="contentholder__top">
              <h4>BeeQuest</h4>
              <p className="con-text">Edit BeeQuest Details </p>
            </div>
            <div className="grid intern">
              <div className="card intern-card">
                <p className="subhead">Competition Details</p>
                {competitiondata ? (
                  <>
                    <div className="inputholder">
                      <p className="inputholder__text">
                        Class 1-3 link<span>*</span>
                      </p>
                      <input
                        type="url"
                        className="inputholder__box"
                        placeholder="Competition Link"
                        disabled={!fab}
                        value={competitionLinks.link1}
                        onChange={(e) => {
                          setCompetitionLinks({
                            ...competitionLinks,
                            link1: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="inputholder">
                      <p className="inputholder__text">
                        Class 4-6 link<span>*</span>
                      </p>
                      <input
                        type="url"
                        disabled={!fab}
                        value={competitionLinks.link2}
                        className="inputholder__box"
                        placeholder="Competition Link"
                        onChange={(e) => {
                          setCompetitionLinks({
                            ...competitionLinks,
                            link2: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="inputholder">
                      <p className="inputholder__text">
                        Class 7-10 link<span>*</span>
                      </p>
                      <input
                        type="url"
                        disabled={!fab}
                        className="inputholder__box"
                        placeholder="Competition Link"
                        value={competitionLinks.link3}
                        onChange={(e) => {
                          setCompetitionLinks({
                            ...competitionLinks,
                            link3: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex-center fullheight-2">
                    <CircularProgress color="primary" />
                  </div>
                )}
              </div>
              <div className="comp-col">
                <div
                  className="card intern-card competition-card"
                  style={{
                    marginBottom: "2.5rem",
                  }}
                >
                  <p className="subhead">Competition Status</p>

                  {competitiondata ? (
                    <div className=" flex-start flex-dir-row ">
                      <Switch
                        disabled={!fab}
                        checked={compactive}
                        color="primary"
                        onChange={(e) => {
                          setCompactive(e.target.checked);
                        }}
                      />
                      <p className="inputholder__text switch-text">
                        {compactive
                          ? "Competition is Active "
                          : "Competition is not Active "}
                      </p>
                    </div>
                  ) : (
                    <div className="flex-center fullheight-2">
                      <CircularProgress color="primary" />
                    </div>
                  )}
                </div>
                <div className="card intern-card competition-card flex-center flex-dir-col competition__card">
                  <p
                    className="competition__items"
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/forms/u/0/",
                        "_blank"
                      );
                    }}
                  >
                    GOOGLE FORMS
                  </p>
                  <p
                    className="competition__items"
                    onClick={() => {
                      window.open("https://try.airtable.com/forms", "_blank");
                    }}
                  >
                    AIRTABLE FORMS
                  </p>
                  <p
                    className="competition__items"
                    onClick={() => {
                      window.open("https://www.quiz-maker.com/", "_blank");
                    }}
                  >
                    QUIZ MAKER
                  </p>

                  <p
                    className="competition__items"
                    onClick={() => {
                      window.open("https://presenter.jivrus.com/", "_blank");
                    }}
                  >
                    JIVVRUS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fab ? (
          <>
            <div
              className="fab fab__fab1 fab__bg1 flex-center"
              onClick={() => {
                setFAB(false);

                SetInitialData();
              }}
            >
              <MdClose className="fab__icon" />
            </div>
            <div
              className="fab fab__fab2 fab__bg2 flex-center"
              onClick={() => {
                setFAB(false);
                UploadCompetitionData();
              }}
            >
              <MdDone className="fab__icon" />
            </div>
          </>
        ) : (
          <div
            className="fab fab__fab1 fab__bg flex-center"
            onClick={() => {
              setFAB(true);
            }}
          >
            <MdCreate className="fab__icon" />
          </div>
        )}
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Competition;
