import Profile from "../Home/profile";
import Nav from "../Nav/nav";
import "./job.scss";

import React from "react";

import JobDetails from "./jobDetails";
import JobRequests from "./jobRequest";

///////////
const Job = () => {
  /////////////////////

  return (
    <>
      <div className="background base flex-center flex-dir-row">
        <Nav loc={"job"} />
        <Profile />
        <div className=" flex-start flex-dir-col  contentholder">
          <div className="contentholder__top">
            <h4>Career </h4>
            <p className="con-text">
              Intern/Full Time job reguests and job positions{" "}
            </p>
          </div>
          <div className="grid adminpanel job">
            <JobDetails />
            <JobRequests />
            <div className="comp-col">&nbsp;</div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Job;
