import React, { useState } from "react";
////////////////////////Material UI Import Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

/////////////////////////Material UI Import Radio
import Radio from "@material-ui/core/Radio";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { db } from "../../firebase/firebase";
import SimpleBackdrop from "../Login/backdrop";
//////////////////Alert
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useEffect } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
/////////////////////////////////
////////////////tHEME DEFINED
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
    secondary: {
      main: "#2979ff",
    },
  },
});

////////////////////////////////
const EditAdminDialogue = ({
  editAdmin,
  handleCloseEdit,
  admin,
  handleGetData,
  editAdminData,
}) => {
  let adminmails = [];
  admin.forEach((element) => {
    adminmails.push(element.gmail);
  });

  ////////////////////////////////////////////////
  //////////////////////

  const ref = db.collection("adminroles");
  const [newAdminGmail, setNewAdminGmail] = useState(""); //input Gmail from inputbox
  const [newAdminRole, setNewAdminRole] = useState(""); //radio
  useEffect(() => {
    setNewAdminGmail(editAdminData.gmail);
    setNewAdminRole(editAdminData.role);
  }, [editAdminData]);

  //////////////////////
  /////////////Snackbar
  const [snakbar, openSnackbar] = useState(false); ////Snakbar Response
  const [snakbarstatus, openSnackbarStatus] = useState({
    status: "error",
    text: "Something Went Wrong",
  }); ////Response type
  function HideAlert() {
    //Hide alert
    setTimeout(() => {
      openSnackbar(false);
    }, 4000);
  }

  //////////////////////
  /////////////Update Data
  const [uploading, setUploading] = useState(false);
  function UpdateData() {
    if (!newAdminGmail.includes("gmail")) {
      openSnackbar(true);
      HideAlert();
      openSnackbarStatus({
        status: "error",
        text: "Please add a GMail account ",
      });
    } else if (newAdminGmail.length <= 4) {
      openSnackbar(true);
      HideAlert();
      openSnackbarStatus({
        status: "error",
        text: "Please Enter  a valid GMail account ",
      });
    } else if (newAdminGmail.length > 4) {
      setUploading(true);

      handleCloseEdit();
      const data = {
        gmail: newAdminGmail,
        role: newAdminRole,
      };
      ref
        .doc(editAdminData.uniqueid)
        .update(data)
        .then(() => {
          handleGetData();
          setUploading(false);
        })
        .catch((err) => {
          setUploading(false);
          openSnackbar(true);
          HideAlert();
          openSnackbarStatus({
            status: "error",
            text: "Something Went Wrong ",
          });
        });
    }
  }

  return (
    <>
      {uploading ? <SimpleBackdrop /> : null}

      <ThemeProvider theme={theme}>
        <Dialog open={editAdmin} onClose={handleCloseEdit}>
          <DialogTitle>
            <span
              style={{
                fontFamily: "Jost",
                fontSize: "1.9rem",
                fontWeight: "500",
                color: "#2979ff",
              }}
            >
              Edit Admin
            </span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.6rem",
                  fontWeight: "400",
                }}
              >
                To add new admin to Dashboard.Co-Admin cannot add or remove
                admins
              </span>
            </DialogContentText>
            <div className="inputholder">
              <p className="inputholder__text">GMail</p>
              <input
                type="email"
                className="inputholder__box"
                onChange={(e) => {
                  setNewAdminGmail(e.target.value);
                }}
                defaultValue={newAdminGmail}
                placeholder="Name of Intern"
              />
            </div>
            <div className="inputholder">
              <p className="inputholder__text">Role</p>
              <RadioGroup
                defaultValue={newAdminRole}
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  setNewAdminRole(e.target.value);
                }}
              >
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="co-admin"
                  control={<Radio size="medium" />}
                  label="Co-Admin"
                />
              </RadioGroup>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseEdit();
              }}
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                Cancel
              </span>
            </Button>
            <Button
              onClick={() => {
                UpdateData();
              }}
            >
              <span
                style={{
                  fontFamily: "Jost",
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                Save Changes
              </span>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snakbar}
      >
        <Alert
          onClose={() => {
            openSnackbar(false);
          }}
          severity={snakbarstatus.status}
        >
          {snakbarstatus === "success" ? (
            <span className="alerttext">Changes Saved</span>
          ) : (
            <span className="alerttext">{snakbarstatus.text}</span>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};
export default EditAdminDialogue;
