import Nav from "../Nav/nav";
import Barchart from "./barchart";
import "./home.scss";
import Tooltip from "@material-ui/core/Tooltip";
import {
  IoImage,
  IoLogoWhatsapp,
  IoExtensionPuzzleOutline,
  IoEarthOutline,
  IoAnalyticsSharp,
} from "react-icons/io5";
import Profile from "./profile";
import { db } from "../../firebase/firebase";
import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";

const Home = () => {
  //////////////////////////
  ///////////Get Firebase Data
  const ref = db.collection("analytics").doc("vWmChsKXKgrZlsPEoTQb");
  const [data, setData] = useState(false);
  function GetData() {
    ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          setData(doc.data());
          console.log("Document data:", doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }
  useEffect(() => {
    GetData();
  }, []);
  return (
    <div className="background base flex-center flex-dir-row">
      <Nav loc={"home"} />
      <Profile />
      <div className="contentholder flex-start flex-dir-col contentholderx">
        <div className="contentholder__top">
          <h4>Dashboard</h4>
          <p className="con-text">Everything in a single place</p>
        </div>

        <div className="grid home__grid">
          <div className="home__grid__con1 flex-start">
            <div className="card card-container">
              <p className="subhead">Website Visits</p>
              {data ? (
                <div className="statistics grid">
                  <div className="statistics__col1">
                    <Tooltip
                      title={
                        ("Total:" + data.totalvisit,
                        "Unique:" + data.uniqueVisit)
                      }
                      aria-label="add"
                    >
                      <div className="barholder">
                        <Barchart
                          data={[
                            (data.totalvisit / (data.totalvisit + 5)) * 100,
                            0,
                          ]}
                        />

                        <Barchart
                          data={[
                            0,
                            (data.uniqueVisit / (data.totalvisit + 5)) * 100,
                          ]}
                        />
                      </div>
                    </Tooltip>
                    <div className="bar-baseline"></div>
                  </div>
                  <div className="statistics__col2 flex-center  flex-dir-col">
                    <div className="statistics__col2__sec ">
                      <p className="con-text">Total Visits</p>
                      <p className="sitenum sitenum-light">
                        {data.totalvisit < 10
                          ? "0" + data.totalvisit
                          : data.totalvisit}
                      </p>
                    </div>
                    <div className="statistics__col2__sec">
                      <p className="con-text">Unique Visits</p>
                      <p className="sitenum ">
                        {data.uniqueVisit < 10
                          ? "0" + data.uniqueVisit
                          : data.uniqueVisit}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex-center fullheight-2">
                  <CircularProgress color="primary" />
                </div>
              )}
            </div>
          </div>
          <div className="home__grid__con2 home__grid__con1">
            <div className="card card-container card-container2">
              <div className="indicator">
                <div className="indicator__col flex-center flex-dir-row">
                  <div className="circle"></div>
                  <p className="indicator__text">Total Visitss</p>
                </div>
                <div className="indicator__col flex-center flex-dir-row ">
                  <div className="circle circle2"></div>
                  <p className="indicator__text">Unique Visits</p>
                </div>
              </div>
              <p className="subhead-2">This Week</p>
              <div className="barholder barholder2">
                <Barchart data={[70, 25]} />
                <Barchart data={[50, 30]} /> <Barchart data={[68, 30]} />
                <Barchart data={[35, 30]} /> <Barchart data={[60, 20]} />
                <Barchart data={[40, 30]} /> <Barchart data={[35, 30]} />
              </div>
              <div className="bar-baseline"></div>
            </div>
          </div>
          <div className="home__grid__con3 home__grid__con1">
            <div className="card card-container home-btm-col grid">
              <div
                className="gray-card flex-center flex-space-between"
                onClick={() => {
                  window.open("https://beylabee.com/", "_blank");
                }}
              >
                <IoEarthOutline className="gray-card__icn" />
                <div className="gray-card__row  flex-center flex-dir-col ">
                  <p className="gray-card__row__text">Open Website</p>
                  <p className="gray-card__row__num">beylabee.com</p>
                </div>
              </div>
              <div
                className="gray-card flex-center flex-space-between"
                onClick={() => {
                  window.open("https://analytics.google.com/", "_blank");
                }}
              >
                <IoAnalyticsSharp className="gray-card__icn" />
                <div className="gray-card__row  flex-center flex-dir-col ">
                  <p className="gray-card__row__text">Analytics</p>
                  <p className="gray-card__row__num">Google analytics</p>
                </div>
              </div>
              <div className="gray-card flex-center flex-space-between">
                <IoImage
                  className="gray-card__icn"
                  onClick={() => {
                    window.open("https://imagecompressor.com/", "_blank");
                  }}
                />
                <div className="gray-card__row  flex-center flex-dir-col ">
                  <p className="gray-card__row__text">Image Compressor</p>
                  <p className="gray-card__row__num">imagecompressor.com</p>
                </div>
              </div>

              <div
                className="gray-card flex-center flex-space-between "
                onClick={() => {
                  window.open("https://presenter.jivrus.com/", "_blank");
                }}
              >
                <IoExtensionPuzzleOutline className="gray-card__icn" />
                <div className="gray-card__row  flex-center flex-dir-col ">
                  <p className="gray-card__row__text">Create Quiz</p>
                  <p className="gray-card__row__num">Jivrus.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
